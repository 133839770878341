.bsn-flt-style {
  display: flex;
  padding: 0px 0px 0px 0px;
  flex-direction: column;
  font: 13px Arial, Helvetica, sans-serif;
  color: var(--edata-t-dark);
  background-color: #c2c2c2;
}

.bsn-flt-frame_grid {
  display: grid;

  /* responsive */

  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 0rem;
  row-gap: 0rem;
  align-items: center;
}

.bsn-flt-style-select-field {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #c2c2c2;
  -moz-box-shadow: 1px 1px 4px #ebebeb;
  -webkit-box-shadow: 1px 1px 4px #ebebeb;
  box-shadow: 1px 1px 4px #ebebeb;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 0px;
  outline: none;
  width: 100%;
  height: 12px;
}
.bsn-flt-style-select-field:focus,
.bsn-flt-style-select-field:focus-within {
  border: 3px solid var(--edata-lightdark);
}

.bsn-flt-style-row {
  display: flex;
  margin-bottom: 0rem;
  flex-direction: row;
  align-items: baseline;
  background: #c2c2c2;
}

.bsn-flt-frame_grid_block {
  display: block;
  background-color: var(--edata-light);
  transition: all 0.3s ease-in-out 0s;
  /* responsive */
  padding: 0rem;
  border-radius: 0rem;
  align-items: center;
  vertical-align: middle;
  background: red;
}

.bsn-flt-style_button {
  border: none;
  padding: 8px 8px 8px 8px;
  background: var(--edata-dark);
  color: var(--edata-light);
  box-shadow: 1px 1px 4px #dadada;
  -moz-box-shadow: 1px 1px 4px #dadada;
  -webkit-box-shadow: 1px 1px 4px #dadada;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}
.bsn-flt-style_button:hover {
  background: var(--edata-darklight);
  color: var(--edata-light);
}

.bsn-flt-style_button_csv {
  border: none;
  padding: 1px 1x 1px 1px;
  background: var(--edata-dark);
  color: var(--edata-light);
  box-shadow: 1px 1px 4px #dadada;
  -moz-box-shadow: 1px 1px 4px #dadada;
  -webkit-box-shadow: 1px 1px 4px #dadada;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  max-height: 30px;
  text-align: center;
  vertical-align: center;
  margin-left: 20px;
  font-size: small;
}
.bsn-flt-style_button_csv:hover {
  background: var(--edata-darklight);
  color: var(--edata-light);
}
@media only screen and (max-width: 900px) {
  .bsn-flt-frame_grid {
    grid-template-columns: 1fr;
    row-gap: 0.1rem;
  }

  .bsn-flt-frame_grid_block {
    transition: all 0.3s ease-in-out 0s;

    padding: 0rem;
    border-radius: 0.3rem;
  }
}

@media only screen and (max-width: 640px) {
  .bsn-flt-frame_grid {
    grid-template-columns: 1fr;
    row-gap: 0rem;
  }

  .bsn-flt-frame_grid_block {
    transition: all 0.3s ease-in-out 0s;
    padding: 0rem;
    border-radius: 0.1rem;
  }
}
