.page-footer-ads {
  grid-column: span 12 / span 12;

  background-color: var(--edata-lightdark);

  font-size: 1.6rem;
  line-height: 2.4rem;
}

.page-footer-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.2rem;
  flex-direction: row;
}
.edata_ad_slot {
  margin: 2px;
  padding: 2px;
  display: inline-block;
  width: 260px;
  height: 120px;
  background-color: var(--edata-light);
}

@media only screen and (max-width: 640px) {
  .page-footer-ads {
    /* responsive */

    font-size: 1.4rem;
    line-height: 2.2rem;
    background-color: var(--edata-lightdark);
  }
  .page-footer-row {
    justify-content: center;
    align-items: center;
    margin: 0.1rem;
    flex-direction: column;
  }
  .edata_ad_slot {
    margin: 1px;
    padding: 1px;
    width: 350px;
    height: 100px;
    background-color: var(--edata-light);
  }
}
