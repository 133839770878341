.subs-plan-table {
  display: table;
  border-collapse: collapse;
  width: 100%;
}
.subs-plan-table-row {
  display: table-row;
}
.subs-plan-table-cell {
  background: var(--edata-dark-lightdark-gradient);
  /*color: var(--edata-t-dark);*/
  color: var(--edata-light);
  display: table-cell;
  border: 1px solid var(--edata-lightdark);
  /*display: table-cell;*/
  border-width: 4px;

  width: 33%;
}

.subs-plan-style {
  display: flex;
  padding: 0px 0px 0px 0px;
  flex-direction: column;
  color: var(--edata-light);
  font-size: 1.6rem;
  line-height: 2.4rem;
  /*background-color: var(--edata-lightdark);*/
}

.subs-plan-heading {
  width: 100%;
  font-weight: bold;
  font-style: italic;
  /*border-bottom: 2px solid black;*/
  margin-top: 10px;

  padding-bottom: 3px;
}
.subs-plan-label {
  width: 100%;

  padding-top: 2px;
  padding-right: 25px;
  padding-left: 25px;
  /*border-bottom: 2px solid black;*/
  margin: 2px 2px 2px 2px;
}

.subs-plan-frame_grid {
  display: grid;

  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 0.5rem;
  row-gap: 0rem;
  align-items: top;
}

.subs-plan-style-row {
  display: flex;
  /*border-bottom: 2px solid black;*/
  margin-bottom: 0rem;
  vertical-align: center;
  flex-direction: row;
  /*background: green;*/
  height: 50px;
  /*background: #c2c2c2;*/
}
.subs-plan-style-header_row {
  display: flex;
  /*border-bottom: 2px solid black;*/
  margin-bottom: 0rem;
  flex-direction: row;
  /*background: green;*/
  text-align: center;
  height: 40px;
  align-content: center;
  /*background: #c2c2c2;*/
}

.subs-plan-style-last_row {
  display: flex;
  /*border-bottom: 2px solid black;*/
  justify-content: center;

  margin-bottom: 0rem;
  flex-direction: row;
  /*background: green;*/
  height: 100%;
  /*background: #c2c2c2;*/
}

.subs-plan_your-plan {
  display: block;
  font-weight: bold;
  font-style: italic;
  background-color: var(--edata-darklight);
  color: var(--edata-t-light_spot);
  height: 30px;
  width: 150px;
  border-radius: 16px;
  margin: 0px;
  align-content: center;
  vertical-align: bottom;
  box-shadow: 1px 1px 4px #dadada;
}

.subs-plan-frame_grid_block {
  background-color: var(--edata-light);
  transition: all 0.3s ease-in-out 0s;
  /* responsive */
  padding: 1rem;
  border-radius: 0.5rem;
  align-items: center;
  vertical-align: middle;
  height: 100%;
  /*background: red;*/
}

.subs-plan-style_button {
  border: none;
  padding: 8px 8px 8px 8px;
  background: var(--edata-dark);
  color: var(--edata-light);
  box-shadow: 1px 1px 4px #dadada;
  -moz-box-shadow: 1px 1px 4px #dadada;
  -webkit-box-shadow: 1px 1px 4px #dadada;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}
.subs-plan-style_button:hover {
  background: var(--edata-darklight);
  color: var(--edata-light);
}

@media only screen and (max-width: 900px) {
  .subs-plan-frame_grid {
    grid-template-columns: 1fr;
    row-gap: 0.1rem;
  }

  .subs-plan-frame_grid_block {
    transition: all 0.3s ease-in-out 0s;

    padding: 0rem;
    border-radius: 0.3rem;
  }
  .subs-plan-table-cell {
    display: table-row;
    background: var(--edata-dark-lightdark-gradient);
    /*color: var(--edata-t-dark);*/
    color: var(--edata-light);
    border: 1px solid var(--edata-lightdark);
    /*display: table-cell;*/
    border-width: 2px;

    width: 33%;
  }
  .subs-plan-style {
    display: flex;
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    color: var(--edata-light);
    font-size: 1.4rem;
    line-height: 2rem;
    /*background-color: var(--edata-lightdark);*/
  }
}

@media only screen and (max-width: 640px) {
  .subs-plan-frame_grid {
    grid-template-columns: 1fr;
    row-gap: 0rem;
  }

  .subs-plan-frame_grid_block {
    transition: all 0.3s ease-in-out 0s;
    padding: 0rem;
    border-radius: 0.1rem;
  }
  .subs-plan-table-cell {
    display: table-row;
    background: var(--edata-dark-lightdark-gradient);
    /*color: var(--edata-t-dark);*/
    color: var(--edata-light);
    border: 1px solid var(--edata-lightdark);
    /*display: table-cell;*/
    border-width: 1px;

    width: 33%;
  }
  .subs-plan-style {
    display: flex;
    padding: 0px 0px 0px 0px;
    flex-direction: column;
    color: var(--edata-light);
    font-size: 1.2rem;
    line-height: 1.8rem;
    /*background-color: var(--edata-lightdark);*/
  }
}
