/* General */

* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  text-rendering: geometricPrecision;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--edata-light);

  color: var(--edata-dark);
  font-family: var(--font-primary);

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-y: scroll;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-secondary);
  font-weight: 400;
  color: rgba(0, 0, 0, 0.86);
}

h1,
h2,
h3 {
  margin-top: 3.2rem;
  margin-bottom: 1.6rem;
}

h4,
h5,
h6 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.8rem;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 1.6rem;
}

h6 {
  font-size: 1.4rem;
}

p {
  margin: 0 0 1.6rem;
}

strong {
  font-weight: 400;
}

small {
  font-size: 1.2rem;
}

blockquote {
  padding: 1.6rem 3.2rem;
  margin: 0 0 3.2rem;

  border-left: 8px solid #eee;

  font-size: 1.6rem;
  font-style: italic;
}

body,
button,
input,
select,
textarea {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.edata_a {
  color: var(--edata-t-dark);
  text-decoration: underline solid 1px var(--edata-t-dark);
}
.edata_a:hover {
  color: var(--edata-darklight);
  text-decoration: underline solid 1px var(--edata-darklight);
}

.edata_a_light {
  color: var(--edata-light);
  text-decoration: underline solid 1px var(--edata-light);
}
.edata_a_light:hover {
  color: var(--edata-lightdark);
  text-decoration: underline solid 1px var(--edata-lightdark);
}

.edata_pre {
  color: var(--white);
  background-color: var(--black);
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

a {
  color: var(--white);
  text-decoration: none;
}

figure {
  margin: 0;
}
img {
  vertical-align: middle;
}

code,
pre {
  font-family: "Fira Code", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
  width: 100%;
}

code {
  color: var(--emerald);
}

#root {
  height: 100%;
  width: 100%;
}

.width_100pr {
  width: 100%;
}

.disp_none {
  display: none;
  visibility: hidden;
}

.t-bold {
  font-weight: bold;
}

.t-red {
  color: red;
}
.t-blue {
  color: blue;
}

.t-dark {
  color: var(--edata-t-dark);
}
.t-light_spot {
  color: var(--edata-t-light_spot);
}
.t-light_alum {
  color: var(--light-aluminium);
}

.bgr-darklight {
  background-color: var(--edata-darklight);
}

.b-red {
  border: 1px solid rgb(204, 0, 27);
  height: 26px;
}

@media only screen and (max-width: 640px) {
  .mobile-scroll-lock {
    overflow: hidden;
  }
}
