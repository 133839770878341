.table_component {
  overflow: auto;
  width: 100%;
  font-size: 1.2rem;
  line-height: 1.8rem;
}

.table_component table {
  border: 1px solid #dededf;
  height: 100%;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 1px;
  text-align: left;
}

.table_component caption {
  caption-side: top;
  text-align: left;
  color: #000000;
  font-weight: bold;
}

.table_component th {
  border: 1px solid #dededf;
  background-color: #eceff1;
  color: hsl(0, 0%, 0%);
  padding: 5px;
}

.table_component td {
  border: 1px solid #dededf;
  background-color: #ffffff;
  color: #000000;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 5px;
}
