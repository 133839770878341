.page-layout {
  max-width: 1240px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-gap: 1px;
}
/*
.page-layout {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  width: 100%;

  }
  .page-layout__content {
  grid-column: span 11 / span 11;
  background-color: var(--edata-lightdark);
  margin: 0;
}

*/
.page-layout__content {
  grid-column: span 12 / span 12;
  background-color: var(--edata-lightdark);
  margin: 0;
}

.page-layout__sidebar {
  grid-column: span 1 / span 1;
  background-color: var(--edata-lightdark);
  margin: 0;
}

/*
.page-layout__content {
  flex: 1;
  flex-basis: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  margin-top: 8rem;
  max-width: 120rem;
  width: 80%;
}
  */
/*
.page-layout__sidebar {
  flex: 1;
  flex-basis: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  margin-top: 8rem;
  max-width: 120rem;
  width: 20%;
  background-color: lightgray;
}
*/
@media only screen and (max-width: 640px) {
  .page-layout__content {
    margin-top: 6.4rem;
  }
  .page-layout__content {
    grid-column: span 12 / span 12;
    background-color: var(--edata-lightdark);
    margin: 0;
  }
  .page-layout__sidebar {
    background-color: var(--edata-lightdark);
    margin: 0;
  }
}
