.hero-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: var(--edata-dark-lightdark-gradient);
  /*color: var(--edata-t-dark);*/
  color: var(--edata-light);
  margin: 0 auto;
  padding: 3.2rem 6.4rem;
}

hero-banner--ul {
  padding: 0;
  margin: 0;
  display: inline-block;
  text-align: left;
  width: 90px;
}

hero-banner--li {
  text-align: left;
  align-items: left;
  /*padding: 5px 4px 6px 7px;*/
  margin-top: 3px;
  margin-bottom: 3px;
  list-style: none;
  padding-left: 20px;
}

.hero-banner--edata-dark-lightdark {
  background: var(--edata-dark-lightdark-gradient);
}

.hero-banner--yellow-mandarine {
  background: var(--yellow-mandarine-gradient);
}
.hero-banner--pink-yellow {
  background: var(--pink-yellow-gradient);
}

.hero-banner--blue-aqua {
  background: var(--blue-aqua-gradient);
}

.hero-banner--aqua-emerald {
  background: var(--aqua-emerald-gradient);
}

.hero-banner--emerald-yellow {
  background: var(--emerald-yellow-gradient);
}

.hero-banner__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  /*
  margin-left: 100px;
  padding-left: 100px;
  background-color: var(--white);
  border-radius: 50%;
width: 103.8rem;
  height: 40.8rem;
}
  */
  box-shadow: 0 2px 4px rgb(0 0 0 / 12%);

  /* responsive */
  width: 100%;
  height: 40.8rem;
}

.hero-banner__image {
  width: 100%;
  height: 40.8rem;
}

.hero-banner__image--small {
  width: 7.6rem;
  height: 7.6rem;
}

.hero-banner__headline {
  letter-spacing: 1.5px;
  text-align: center;
  /* responsive */
  margin: 2.4rem 0 8px 0;
  /*color: var(--edata-t-dark);*/
  color: var(--edata-light);
  font-size: 2.8rem;
}

.hero-banner__description {
  max-width: 100rem;
  text-align: left;
  margin-right: 3.2rem;
  margin-left: 3.2rem;
  margin-bottom: 1rem;
  /*color: var(--edata-t-dark);*/
  color: var(--edata-light);
  font-size: 2rem;
  line-height: 3.2rem;
  padding-bottom: 0.5rem;
}

.svr-style-label {
  display: block;
  margin: 0px 0px 15px 0px;
}
.svr-style-label-span {
  width: 120px;
  font-weight: bold;
  float: left;
  padding-top: 2px;
  padding-right: 15px;
}
.svr-span-200px {
  width: 200px;
}

@media only screen and (max-width: 640px) {
  .hero-banner {
    padding: 3.2rem 2.6rem;
  }

  .hero-banner__logo {
    width: 100%;
    height: 20rem;
  }

  .hero-banner__image {
    width: 100%;
    height: 20rem;
  }

  .hero-banner__image--small {
    width: 6rem;
    height: 6rem;
  }

  .hero-banner__headline {
    font-size: 2rem;
    letter-spacing: 1px;
  }

  .hero-banner__description {
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-left: 0.5rem;
  }
}
