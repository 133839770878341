.tabs-component [role="tablist"] {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--edata-dark);
  position: relative;
}

.tabs-component [role="tablist"] button {
  color: var(--edata-t-dark);
  font-size: 1.6rem;
  font-weight: bold;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  display: block;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin: 0 1px;
  outline: none;
  background-color: var(--edata-lightdark);
  transition: border 0.2s ease-in-out 0s;
}

.tabs-component [role="tablist"] button.active,
.tabs-component [role="tablist"] button:focus,
.tabs-component [role="tablist"] button:hover {
  border-width: 1px;
  border-color: var(--edata-light);
}

.tabs-component [role="tablist"] button.active {
  color: var(--edata-light);
  background-color: var(--edata-dark);
}

.tabs-component [role="tabpanel"] {
  text-align: left;
  padding: 1rem;
  background-color: var(--edata-lightdark);
  box-shadow: 1px 1px 2px rgb(204 204 204 / 75%);
}

.tabs-component.vertical {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.tabs-component.vertical [role="tablist"] {
  flex-direction: column;
}

.tabs-component.vertical [role="tabpanel"] {
  flex: 1;
}

.tabs-component [role="tablist"] button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin: 0;
}

.tabs-component.vertical [role="tablist"] button.active,
.tabs-component.vertical [role="tablist"] button:focus,
.tabs-component.vertical [role="tablist"] button:hover {
  border-color: #e9ecef transparent #e9ecef #b41c1c;
}
