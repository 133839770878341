/*
--edata-light: #e1f7f5;
--edata-lightdark: #b0def3;
--edata-darklight: #0e46a3;
--edata-dark: #1e0342;
--edata-t-dark: black;
--edata-t-light_spot: #6ef169;*/

.table__hyperlink {
  letter-spacing: 0.001rem;
}
.table__hyperlink,
.page-footer__hyperlink:active,
.page-footer__hyperlink:visited {
  color: var(--edata-darklight);
}

.table__hyperlink:hover {
  color: var(-edata-lightdark);
}

.stock_return_image {
  width: 100%;
  height: 100%;
  margin-right: 50px;
}
