.tooltipContainer {
  position: relative;
  margin-right: 10px;
}

.tooltipContainer:hover .tooltip {
  visibility: visible;
}

.tooltip {
  position: absolute;
  color: var(--edata-t-dark);
  background-color: var(--edata-lightdark);
  left: 50%;
  /*  top: -16px;
  
  */
  top: 22px;
  transform: translateX(-50%) translateY(-100%);
  padding: 3px 10px;
  border-radius: 5px;
  text-align: center;
  /*min-width: max-content;*/
  width: 400px;
  font-size: 14px;
  font-weight: 400;
  visibility: hidden;
}
/*
.tooltip:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: calc(50% - 10px);

  border-top: 10px solid var(--edata-light);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 1px solid transparent;

  
}
*/
.disabled {
  visibility: hidden;
}

.down {
  top: 22px;
  transform: translateX(-50%) translateY(100%);
}

.down:before {
  top: calc(-100% + 14px);
  border-bottom: 10px solid var(--edata-light);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 1px solid transparent;
}
