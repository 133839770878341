.ufrm-style {
  display: flex;

  padding: 20px 12px 10px 20px;
  flex-direction: column;
  font: 13px Arial, Helvetica, sans-serif;
  color: var(--edata-t-dark);
  background-color: #c2c2c2;
}
.ufrm-style-bsn-details {
  display: flex;
  max-width: 100%;
  flex-direction: column;
  font: 14px Arial, Helvetica, sans-serif;
  color: var(--edata-t-dark);
  padding: 0px;
}
.bsn_table {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border-collapse: collapse;
  border-spacing: 0;
}

.ufrm-style-row {
  display: flex;
  margin-bottom: 0.5rem;
  flex-direction: row;
}

.ufrm-style-flex {
  vertical-align: top;
  display: flex;
  margin-bottom: 0.5rem;
}

.ufrm-style-heading {
  font-weight: bold;
  font-style: italic;
  border-bottom: 2px solid #ddd;
  margin-bottom: 20px;
  font-size: 15px;
  padding-bottom: 3px;
}
.ufrm-style-label {
  display: block;
  margin: 0px 0px 15px 0px;
}
.ufrm-style-label-span {
  width: 150px;
  font-weight: bold;
  float: left;
  padding-top: 8px;
  padding-right: 5px;
}
.ufrm-span-200px {
  width: 200px;
}

.ufrm-style-tel-number-field {
  width: 40px;
  text-align: center;
}
.ufrm-style-checkbox-field {
  width: 2em;
  height: 2rem;
  accent-color: var(--edata-dark);
}

.ufrm-style-input_disable {
  pointer-events: none;
  background: var(--edata-light);
}

.ufrm-style-input-field,
.ufrm-style-tel-number-field,
.ufrm-style-textarea-field,
.bsn-flt-style-select-field {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #c2c2c2;
  -moz-box-shadow: 1px 1px 4px #ebebeb;
  -webkit-box-shadow: 1px 1px 4px #ebebeb;
  box-shadow: 1px 1px 4px #ebebeb;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 7px;
  outline: none;
  width: 48%;
  height: 26px;
}
.ufrm-style-input-field:focus,
.ufrm-style-tel-number-field:focus,
.ufrm-style-textarea-field:focus,
.bsn-flt-style-select-field:focus-within {
  border: 3px solid var(--edata-lightdark);
}

.ufrm-error {
  font: 13px Arial, Helvetica, sans-serif;
  color: rgb(204, 0, 27);
  font-size: 1.25rem;
  line-height: 1rem;
  margin: 1rem 0rem 1rem 1rem;
}

.ufrm-success {
  font: 13px Arial, Helvetica, sans-serif;
  color: rgb(3, 66, 24);
  font-size: 1.25rem; /* 12px */
  line-height: 2rem; /* 16px */
  margin: 0.25rem 0rem 1rem 1rem;
}
.ufrm-style-access_token_textarea {
  height: 100px;
  width: 100%;
  color: var(--edata-light);
  background: var(--edata-dark);
}
.ufrm-style-textarea-field {
  height: 100px;
  width: 55%;
}
.ufrm-style_button {
  border: none;
  padding: 8px 15px 8px 15px;
  background: var(--edata-dark);
  color: var(--edata-light);
  box-shadow: 1px 1px 4px #dadada;
  -moz-box-shadow: 1px 1px 4px #dadada;
  -webkit-box-shadow: 1px 1px 4px #dadada;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}
.ufrm-style_button:hover {
  background: var(--edata-darklight);
  color: var(--edata-light);
}

.ufrm-frame {
  /* responsive */

  padding: 10px 10px 10px 10px;
  flex-direction: column;
  font: 13px Arial, Helvetica, sans-serif;
  color: var(--edata-t-dark);
  /*background-color: #c2c2c2;*/
  background-color: var(--edata-dark-light);
}

.ufrm-frame_grid {
  display: grid;

  /* responsive */

  grid-template-columns: 1fr 1fr;
  column-gap: 1.8rem;
  row-gap: 1.8rem;
}
.ufrm-title_uline_light {
  color: var(--edata-light);
  /* responsive */
  /* text-decoration: underline solid 2px var(--edata-light);*/
}
.ufrm-title_uline_dark {
  color: var(--edata-t_dark);
  /* responsive */
  text-decoration: underline solid 2px var(--edata-dark);
}

.ufrm-frame_title {
  text-align: left;
  font-weight: bold;
  /* responsive */
  /* text-decoration: underline solid 2px var(--edata-dark);*/

  margin-left: 3rem;
  margin-top: 0;
  margin-bottom: 0.8rem;

  font-size: 1.8rem;
  line-height: 2.7rem;
  text-underline-offset: 2px;

  transition: all 0.3s ease-in-out 0s;
}

.ufrm-frame_grid_dark_style {
  background: var(--edata-dark-lightdark-gradient);
  /*color: var(--edata-t-dark);*/
  color: var(--edata-light);
}

.ufrm-frame_grid_block {
  display: block;

  background-color: var(--edata-light);

  transition: all 0.3s ease-in-out 0s;

  /* responsive */

  padding: 1.8rem;

  border-radius: 0.6rem;
}

@media only screen and (max-width: 900px) {
  .ufrm-frame {
    /* responsive */

    padding: 1px 1px 1px 1px;
    font: 12px Arial, Helvetica, sans-serif;
  }

  .ufrm-frame_grid {
    grid-template-columns: 1fr;
    row-gap: 0.4rem;
  }

  .ufrm-frame_title {
    font-weight: bold;
    text-align: left;

    /* responsive */
    margin-left: 3rem;
    margin-top: 0;
    margin-bottom: 0.8rem;

    font-size: 1.6rem;
    line-height: 1rem;
  }

  .ufrm-frame_grid_block {
    transition: all 0.3s ease-in-out 0s;

    /* responsive */

    padding: 1.4rem;

    border-radius: 0.6rem;
  }
}

@media only screen and (max-width: 640px) {
  .ufrm-frame {
    /* responsive */
    padding: 65px 1px 1px 1px;
    font: 8px Arial, Helvetica, sans-serif;
  }

  .ufrm-frame_grid {
    grid-template-columns: 1fr;
    row-gap: 1.6rem;
  }

  .ufrm-frame_title {
    font-weight: bold;
    text-align: left;

    /* responsive */

    margin-top: 0;
    margin-bottom: 0.8rem;

    font-size: 1.4rem;
    line-height: 1rem;
  }

  .ufrm-frame_grid_block {
    transition: all 0.3s ease-in-out 0s;

    /* responsive */

    padding: 0.4rem;

    border-radius: 0.6rem;
  }
}
